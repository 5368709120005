/*アニメーション用スタイル*/
.js-scroll-fade {
	position: relative;
	opacity: 0;
	transition: all 1s cubic-bezier(.13,.67,.31,.44);
	//ディレイ処理
	@include media-breakpoint-up(md) {
		&.js-delay-01 {
			transition-delay: .5s;
		}
		&.js-delay-02 {
			transition-delay: 1s;
		}
		&.js-delay-03 {
			transition-delay: 1.5s;
		}
		&.js-delay-04 {
			transition-delay: 2s;
		}
		&.js-delay-05 {
			transition-delay: 2.5s;
		}
	}
	//移動アニメーション
	&.js-scroll-top {
		transform: translateY(-40px) translateX(0) !important;
	}
	&.js-scroll-right {
		transform: translateY(0) translateX(40px) !important;
	}
	&.js-scroll-bottom {
		transform: translateY(40px) translateX(0) !important;
	}
	&.js-scroll-left {
		transform: translateY(0) translateX(-40px) !important;
	}
	//回転アニメーション
	&.js-rotation-30 {
		transform: translateY(0) translateX(40px) rotate(30deg) !important;
	}
	&.is-animation {
		opacity: 1;
		transform: translateY(0) translateX(0) rotate(0deg) !important;
	}
}

.inx-card-box-01 {
	position: relative;
	z-index: 5;
	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 1rem;
		border-radius: 10px;
		background: rgba(255, 255, 255, 1);
		transition: all 300ms 0s ease;
	}
	&:hover {
		text-decoration: none;
		&::before {
			width: 95%;
			height: 90%;
			border-radius: 20px;
			background: rgba(255, 255, 255, .7);
		}
	}
}

.inx-card-box-02 {
	position: relative;
	z-index: 5;
	color: $inx-colors-title;
	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 1rem;
		border-radius: $inx-radius-02;
		background: rgba(255, 255, 255, 1);
		transition: all 300ms 0s ease;
	}
	&:hover {
		color: $inx-colors-title;
		text-decoration: none;
		&::before {
			width: 105%;
			height: 105%;
		}
	}
	dt {
		margin-bottom: 16px;
		font-size: .9rem;
		line-height: 1.2rem;
	}
	dd {
		font-size: .8rem;
	}
}

.inx-banner {
	transition: all 300ms 0s ease;
	&:hover {
		transform: scale(0.95);
	}
}