//inx style
@import "inx_scss/inx_variables";

/*blog*/
.inx-blog-box {
	&:not(:first-of-type) {
		margin-top: 48px;
	}
	h2 {
		border-bottom: 2px $inx-colors-01 solid;
		color: $inx-colors-01;
		font-size: 2rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
		}
	}
	h3 {
		margin-bottom: 1rem;
		color: $inx-colors-01;
		font-size: 1.5rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.25rem;
		}
	}
	h4 {
		margin-bottom: 1rem;
		border-bottom: 1px #000 solid;
		color: #000;
		font-size: 1.25rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.15rem;
		}
	}
	h5,h6 {
		margin-bottom: .5rem;
		color: #000;
		font-size: 1.1rem;
	}
	dl {
		dt {
			color: $inx-colors-01;
		}
	}
	ul {
		padding: 0;
		list-style-type: none;
		li {
			position: relative;
			padding-left: 1rem;
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
			&::before {
				position: absolute;
				left: 0rem;
				content: "●";
				color: $inx-colors-01;
				font-size: .5rem;
			}
		}
	}
	table {
		width: 100%;
		tr {
			border: 1px $inx-colors-gray solid;
			th,td {
				padding: .5rem;
			}
			th {
				border-right: 1px $inx-colors-gray solid;
				background: $inx-colors-gray;
			}
		}
	}
}
.inx-accordion_menu {
	& >li {
		position: relative;
		border-bottom: 1px #999 solid;
	}
	a {
		display: block;
		padding-top: .3rem;
		padding-right: 40px;
		padding-bottom: .3rem;
		&:hover {
			background: $inx-colors-a-01-hover;
			color: #333;
			text-decoration: none;
		}
	}
}
.inx-accordion_menu-sub {
	& >li {
		position: relative;
	}
	a {
		display: block;
		padding-left: 1.5rem;
		&::before {
			content: "●";
			position: absolute;
			top: 5px;
			left: .8rem;
			font-size: .5rem;
		}
	}
}
a.inx-accordion_menu-btn {
	display: inline;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 99;
	width: 40px;
	height: 40px;
	&::after {
		content: url(/assets/img/common/arrow.svg);
		display: inline-block;
		position: absolute;
		right: 15px;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 20px;
		height: 20px;
	}
	&:hover {
		background: none;
	}
}
a.inx-accordion_menu-btn[aria-expanded=false]::after{
	transform: rotate(0deg);
	transition-duration: 0.3s;
}
a.inx-accordion_menu-btn[aria-expanded=true]::after{
	transform: rotate(90deg);
	transition-duration: 0.3s;
}
/*end blog*/