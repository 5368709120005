@import "./inx_variables";
.swiper-container-top {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.swiper-container {
	width: 100%;
	height: 100%;
	padding: 0 0 1rem;
}

.swiper-slide {
	@include media-breakpoint-up(lg) {
		width: 25%;
	}
	@include media-breakpoint-down(md) {
		width: 33%;
	}
		@include media-breakpoint-only(xs) {
		width: 80%;
	}
	a {
		img {
			border: 1px $inx-colors-gray solid;
		}
	}
}

.swiper-container-horizontal > .swiper-pagination-bullets {
	left: 50%;
	transform: translateX(-50%) !important;
	bottom: 0;
}

.swiper-pagination-bullet {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 .2rem;
	border-radius: 10%;
	background: rgba(255, 255, 255, .8);
	opacity: 1;
}
.swiper-pagination-bullet-active {
	opacity: 1;
	background: $inx-colors-01;
}

.inx-swiper-banner {
	border-radius: 3px;
	background: rgba(0,0,0,.75);
	dl {
		margin-bottom: 0;
		color: #fff;
		line-height: 1rem;
		dd {
			margin-bottom: 0;
		}
	}
}
.inx-swiper-banner-egyo {
	background: $inx-colors-01;
}
.swiper-button-prev,
.swiper-button-next {
	background-size: 20px;
	background-color: rgba(255, 255, 255, .8);
	padding: .5rem;
}
.swiper-button-prev {
	left: 0;
	right: auto;
}
.swiper-button-next {
	right: 0;
	left: auto;
}