//アイコン関連
.inx-icon-before {
	&::before {
		display: inline-block;
		margin: 0 .3em 0 0;
	}
}

.inx-icon-after {
	&::after {
		display: inline-block;
		margin: 0 0 0 .3em;
	}
}

.inx-icon-before-arrow {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f105';
	}
}

.inx-icon-after-arrow {
	&::after {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f105';
	}
}

.inx-icon-tel {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f095';
	}
}

.inx-icon-mail {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 400;
		content: '\f0e0';
	}
}
.inx-icon-yoto {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f509';
	}
}
.inx-icon-house {
	&::before {
		display: inline-block;
		content: "";
		width: 25px;
		height: 25px;
		background: url(/assets/img/common/icon-house.svg);
		background-repeat: no-repeat;
		background-position: center;
		vertical-align: middle;
	}
}
.inx-icon-keyword {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f4ff';
	}
}
.inx-icon-map {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f3c5';
	}
}
.inx-icon-fax {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f1ac';
	}
}
/*
.inx-icon-clock {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f017';
	}
}

.inx-icon-news {
	&::before {
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f05a';
	}
}
*/
.inx-icon-tag {
	position: relative;
	padding-left: 2em;
	&::before {
		position: absolute;
		left: .5em;
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f02b';
	}
}

.inx-icon-circle-base {
	position: relative;
	padding-left: 1em;
	&::before {
		position: absolute;
		left: 0em;
		content: "●";
		font-weight: 800;
	}
}

.inx-icon-circle-01 {
	&::before {
		color: $inx-colors-01;
	}
}

.inx-icon-circle-02 {
	&::before {
		color: $inx-colors-01;
	}
}
