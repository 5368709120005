@import "inx_scss/mixins/inx_button_mixin";

.inx-btn {
	@include inx-btn;
	width: 100%;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
}
.inx-btn-type-01 {
	@include inx-hover-speed;
	position: relative;
	padding: .6em 2em;
	border: 3px solid $inx-colors-01;
	background: #fff;
	color: $inx-colors-01;
	font-weight: bold;
	text-align: center;
	&::after {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
		font-family: 'Font Awesome 5 Free';
		font-weight: 800;
		content: '\f105';
	}
	&:hover {
		background: $inx-colors-01;
		color: #fff;
		text-decoration: none;
	}
}
.inx-btn-type-02 {
	@include inx-hover-speed;
	padding: .6em 2em;
	background: $inx-colors-01;
	color: #fff;
	font-weight: bold;
	text-align: center;
	&:hover {
		background: $inx-colors-a-02-hover;
		color: #fff;
		text-decoration: none;
	}
}
.inx-btn-type-03 {
	@include inx-hover-speed;
	border: 2px $inx-colors-btn-01 solid;
	border-radius: 6px;
	background: #fff;
	color: $inx-colors-btn-01;
	&:hover {
		border: 2px $inx-colors-btn-01-hover solid;
		color: $inx-colors-btn-01-hover;
	}
}
a.inx-border-btn {
	border: 1px #fff solid;
	border-radius: 3px;
	background: none;
	&:hover {
		background: $inx-colors-a-02-hover;
		color: #fff;
	}
}
button.inx-search-btn {
	border: 1px #fff solid;
	border-radius: 3px;
	background: none;
	&:hover {
		background: $inx-colors-a-02-hover;
	}
	& i {
		color: #fff;
	}
}
/*
.inx-btn-type-02 {
	@include inx-btn-type-02;
}
.inx-btn-type-03 {
	@include inx-btn-type-03;
}
.inx-btn-outline {
	@include inx-btn-outline;
}
.inx-btn-outline-type-01 {
	@include inx-btn-outline-type-01;
}
.inx-btn-outline-type-02 {
	@include inx-btn-outline-type-02;
}*/