@import "functions";
@import "variables";
@import "mixins";

//inx style
@import "inx_scss/inx_variables";
@import "inx_scss/inx_common";
@import "inx_scss/inx_animation";
@import "inx_scss/inx_icon";
@import "inx_scss/inx_header";
@import "inx_scss/inx_footer";
@import "inx_scss/inx_blog";
@import "inx_scss/inx_button";
@import "inx_scss/inx_bootstrap_custom";
@import "inx_scss/inx_swiper";

//page style
//googlefont
//googlefontのURL

/*common*/
body {
	overflow-x: hidden;
	color: #333;
}

button {
	outline: none;
}

.container {
	width: 100%;
	max-width: 1200px;
}

/*common backgroun*/
.inx-bg-box-01 {
	background: $inx-colors-01;
}
.inx-bg-box-02 {
	background: #fff;
}
/*end common backgroun*/

/*common text*/
a {
	@include inx-hover-speed;
	color: $inx-colors-a-01;
	&:hover {
		color: $inx-colors-a-01-hover;
	}
	&.inx-tel {
		color: #333;
		&:hover {
			text-decoration: none;
		}
	}
}
.inx-h2-01 {
	color: $inx-colors-title;
	font-weight: bold;
	&::before {
		content: url(/assets/img/common/icon.png)
	}
}
.inx-h3-01 {
	padding: .3rem 1rem;
	border-radius: 50px;
	background-color: $inx-colors-title;
	color: #fff;
	font-weight: bold;
}
/*end common text*/

hr {
	border-top: 1px $inx-colors-dark-gray solid;
}
.inx-opacity-30 {
	opacity: .3;
}
.inx-opacity-50 {
	opacity: .5;
}
/*end common*/

/*top*/
.inx-address-box {
	border: 2px $inx-colors-title solid;
	background-color: $inx-colors-01;
}
.inx-item-box {
	overflow: hidden;
	border: 3px #3C3A34 solid;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 15px 15px #3C3A34;
}
/*end top*/

/*main img*/
.inx-main-img-box {
	position: relative;
	overflow: hidden;
	height: auto;
	padding-top: 42%;
}
.inx-main-img {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%) !important;
	z-index: -5;
}
.inx-main-img-text {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%) !important;
	padding: 1em;
	//background: rgba(255,255,255,.6);
	img {
		max-width: 280px;
		@include media-breakpoint-only(xs) {
			max-width: 180px;
		}
	}
}
/*end main img*/