//
// inx button(ver 1.0 last-update:2018/04/09)
//
@mixin inx-hover-speed($speed: 0.3s) {
	transition: all $speed ease;
}
@mixin inx-hover-scale($scale: 1.1) {
	&:hover {
		transform: scale($scale);
	}
}
@mixin inx-btn($radius: 5px, $bg-color: #5DC2D0, $text-color: #fff) {
	position: relative;
	overflow: visible;
	border-radius: $radius;
	background: $bg-color;
	color: $text-color;
}
@mixin inx-btn-outline($radius: 5px, $bg-color: #fff, $border-color: #5DC2D0, $text-color: #5DC2D0) {
	position: relative;
	overflow: visible;
	border-color: $border-color;
	border-width: 2px;
	border-style: solid;
	border-radius: $radius;
	background: $bg-color;
	color: $text-color;
}
//シンプルフェードアニメ
@mixin inx-btn-type-01($bg-color: #5DC2D0, $text-color: #fff) {
	&:hover {
		background: $bg-color;
		color: $text-color;
	}
}
@mixin inx-btn-outline-type-01($bg-color: #fff, $border-color: #5DC2D0, $text-color: #5DC2D0) {
	&:hover {
		border-color: $border-color;
		background: $bg-color;
		color:  $text-color;
	}
}
//エコーアニメ
@mixin inx-btn-type-02($radius: 5px, $bg-color: #5DC2D0, $text-color: #fff) {
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		border: 2px solid $bg-color;
		border-radius: $radius;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	&:hover {
		color:  $text-color;
		&:hover::after {
			width: calc(100% + 25px);
			height: calc(100% + 25px);
			opacity: 0;
			transition: all 0.5s ease-in-out;
		}
	}
}
@mixin inx-btn-outline-type-02($radius: 5px, $border-color: #5DC2D0, $text-color: #5DC2D0) {
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		border: 2px solid $border-color;
		border-radius: $radius;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	&:hover {
		color:  $text-color;
		&:hover::after {
			width: calc(100% + 25px);
			height: calc(100% + 25px);
			opacity: 0;
			transition: all 0.5s ease-in-out;
		}
	}
}
//ホバーアニメ
@mixin inx-btn-type-03($radius: 5px, $bg-color: #5DC2D0, $text-color: #fff) {
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		width: calc(100%);
		height: calc(100%);
		border-radius: $radius;
		background: $bg-color;
		opacity: 1;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	&:hover {
		color:  $text-color;
		-webkit-transform: translateY(-5%);
		transform: translateY(-5%);
		transition: all 0.3s ease-in-out;
		&:hover::after {
			width: calc(100% - 5px);
			opacity: .5;
			-webkit-transform: translateX(-50%) translateY(-35%);
			transform: translateX(-50%) translateY(-35%);
			transition: all 0.3s ease-in-out;
		}
	}
}