//カラー設定
$inx-colors-title: #3C3A34;
$inx-colors-01: #f3eacc;
$inx-colors-02: #F2F3F9;

$inx-colors-light-gray: #efefef;
$inx-colors-gray: #dedede;
$inx-colors-dark-gray: #333;
$inx-colors-red: #e83820;
$inx-colors-a-01: $inx-colors-dark-gray;
$inx-colors-a-01-hover: #36b0b7;
$inx-colors-a-02: #fff;
$inx-colors-a-02-hover: #93aced;
$inx-colors-btn-01: #258BEB;
$inx-colors-btn-01-hover: #7AB9E8;

$inx-radius-01: 25px;
$inx-radius-02: 10px;
//背景画像
$inx-bg-img-01-pc: "/assets/img/top/bg_01_pc.png";
$inx-bg-img-01-sp: "/assets/img/top/bg_01_sp.png";
$inx-bg-img-02-pc: "/assets/img/eigyo/bg_01_pc.png";
$inx-bg-img-02-sp: "/assets/img/eigyo/bg_01_sp.png";
$inx-bg-img-03-pc: "/assets/img/eigyo/bg_02_pc.png";
$inx-bg-img-03-sp: "/assets/img/eigyo/bg_02_sp.png";