//
// inx common(ver 1.0 last-update:2018/04/09)
//
@import url('https://fonts.googleapis.com/css?family=Lobster');
//@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import "inx_scss/inx_variables";
@import "./mixins/inx_button_mixin";
/*全体*/
body {
	//font-family: 'Noto Sans CJK JP','YuGothic M','游ゴシック',YuGothic,'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,sans-serif;
	font-family: -apple-system,BlinkMacSystemFont,"Helvetica Neue","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
	word-wrap: break-word;
}
button {
	border: none;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	&:hover {
		text-decoration: none;
	}
}
a {
	outline: none;
	&.inx-no-underline {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}
button,a,input {
	@include inx-hover-speed;
	color: $inx-colors-a-01;
	cursor: pointer;
	&:hover {
		color:$inx-colors-a-01-hover;
	}
	&.inx-btn-01 {
		padding: 25px;
		border-radius: 10px;
		background-color: $inx-colors-a-01;
		color: #fff;
		text-align: center;
		text-decoration: none;
		&:hover {
			background-color: $inx-colors-a-01-hover;
			color: #fff;
			text-decoration: none;
		}
	}
}

.inx-no-click {
	pointer-events: none;
}
/*end 全体*/

/*レイアウト*/
.inx-p {
	padding: 7px;
}

.inx-overflow-hidden {
	overflow: hidden;
}

.inx-overflow-scroll {
	overflow: scroll;
}
/*end レイアウト*/


/*テキスト関連*/
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 1.5rem;
	font-weight: normal;
	line-height: 1.5em;
}

p, a, li, dd {
	line-height: 1.7em;
}

h1 {
	font-size: 4rem;
	@include media-breakpoint-down(sm) {
		font-size: 2rem;
	}
}
h2 {
	font-size: 2rem;
	@include media-breakpoint-down(sm) {
		font-size: 1.5rem;
	}
}
h3 {
	font-size: 1.5rem;
	@include media-breakpoint-down(sm) {
		font-size: 1.2rem;
	}
}
h4 {
	font-size: 1.3rem;
	@include media-breakpoint-down(sm) {
		font-size: 1.05rem;
	}
}
h5 {
	font-size: 1rem;
}
h6 {
	font-size: 1rem;
}
.inx-text-exl {
	font-size: 2rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.45rem;
		}
}
.inx-text-l {
	font-size: 1.35rem;
	@include media-breakpoint-down(sm) {
		font-size: 1.1rem;
	}
}
.inx-text-m {
	font-size: 1rem;
}
.inx-text-s {
	font-size: .8rem;
}
.inx-text-exs {
	font-size: .6rem;
}
.inx-text-bold {
	font-weight: bold;
}
.inx-text-normal {
	font-weight: normal;
}
.inx-text-lh-1 {
	line-height: 1.5em;
}
.inx-text-color-block {
	color: #000 !important;
}
.inx-text-color-white {
	color: #fff !important;
}
.inx-text-color-red {
	color: $inx-colors-red !important;
}
.inx-text-kome {
	position: relative;
	padding-left: 1em;
	&::before {
		content: "※";
		position: absolute;
		left: 0;
	}
}
/*end テキスト関連*/

/*改行関連*/
br {
	&.inx-br-s {
		display: none;
		@include media-breakpoint-down(sm) {
			display: inherit;
		}
	}
	&.inx-br-m {
		display: none;
		@include media-breakpoint-between(md, lg) {
			display: inherit;
		}
	}
	&.inx-br-down-m {
		display: none;
		@include media-breakpoint-down(md) {
			display: inherit;
		}
	}
	&.inx-br-up-m {
		display: none;
		@include media-breakpoint-up(md) {
			display: inherit;
		}
	}
	&.inx-br-l {
		display: none;
		@include media-breakpoint-up(lg) {
			display: inherit;
		}
	}
}
/*end 改行関連*/

/*画像関連*/
.square-box {
	position: relative;
	&::before {
		overflow: hidden;
		content: "";
		display: block;
		padding-top: 60%;//ここの値で画像の高さを調整100%で正方形
	}
}

.square-box-img {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	background-color: #ccc;
	width: 100%;
	height: 100%;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%) !important;
		width: 100%;
		height: auto;
	}
}
/*end 画像関連*/

/*透明度*/
.inx-opacity-80 {
	opacity: .8;
}
.inx-opacity-50 {
	opacity: .5;
}
.inx-opacity-25 {
	opacity: .25;
}
/*end 透明度*/
/*table関連*/
caption {
	color: #000;
	caption-side: top;
}

/*dl関連*/
.inx-dl-news {
	margin: 0;
	dt {
		margin: 0 1em 0 0;
		line-height: 1.8em;
	}
}
/*end dl関連*/

/*フォーム関連*/
.label-req {
	position: relative;
	padding-left: 2rem;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		content: "必須";
		width: 3em;
		margin-right: .5em;
		padding: .2em;
		border-radius: 2px;
		background: $inx-colors-red;
		color: #fff;
		font-size: 70%;
		text-align: center;
	}
}
.inx-form-zip {
	input[type="number"] {
		width: 4rem;
		margin: 0;
		-moz-appearance:textfield;
	}
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}
.inx-form-zip-label {
	padding: 0 .3em;
}
span.input-group-text {
	background: none;
	border: none;
}
/*end フォーム関連*/

/*プリント*/
@media print {
	.inx-no-print {
		display: none;
	}
	.fc-scroller {
		height: 100% !important;
	}
}
/*その他*/
.inx-pegetop {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 10px;
	z-index: 10;
	margin: 0;
	a {
		width: 40px;
		height: 40px;
		padding: .5rem;
		border: 3px $inx-colors-01 solid;
		border-radius: 10%;
		background: #fff;
		color: $inx-colors-01;
		&:hover {
			border: 3px $inx-colors-title solid;
			background: $inx-colors-title;
			color: #fff
		}
		i::before {
			font-size: 20px;
		}
	}
}
.inx-pegetop-hide {
	display: none;
}
a[href^="tel:"] {
	cursor: default;
}
/*end その他*/