@import "./inx_variables";
//button
.btn {
	white-space: normal;
	&:focus,
	&.focus {
		box-shadow: none;
	}
}

//table
.table {
	& thead {
		& th {
			vertical-align: middle;
		}
	}
}

a.page-link {
	color: $inx-colors-a-01;
	&:hover {
		background: $inx-colors-a-01-hover;
		color: #333;
	}
}

.nav-tabs {
	border: none;
	.nav-item {
		a {
			&.nav-link {
				border-color: $inx-colors-gray;
				background: #fff;
				&:hover {
					background: $inx-colors-a-01-hover;
					color: #fff;
				}
			}
			&.active {
				background: $inx-colors-a-01-hover;
				color: #fff;
				cursor: default;
			}
		}
		@include media-breakpoint-down(md) {
			&:not(:first-of-type) {
				a {
					&.nav-link {
						border-radius: 0;
					}
				}
			}
		}
	}
}

.alert-info {
	background-color: #FFF;
}